<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <!-- <h1><span> We Préstamos</span> </h1> -->
        <!-- <h1>Your trusted financial</h1> -->
        <!-- <h1>partner in Nigeria</h1> -->
        <!-- <h2>Your trusted lending platform in Nigeria. </h2> -->
        <!-- <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div> -->
      </div>
    </div>
    <div class="inner inner2">
      <!-- <h1>Why choose  We Préstamos? </h1> -->
      <div class="content flex fw-w jc-sb">
        <div v-for="( item,index ) in inner_2item" :key="index" :style="{width:'24%'}" class="">
          <img :src="item.img">
          <h3>{{ item.title }}</h3>
          <p>{{ item.txt }}</p>
          <p v-if="item.txt2">{{ item.txt2 }}</p>
          <p v-if="item.txt3">{{ item.txt3 }}</p>
        </div>
      </div>
    </div>
    <div class="inner inner3 flex ai-c jc-c">
      <div>
        <h1>Requisitos de Solicitud</h1>
        <p>Requisitos mínimos: Solo necesitas cumplir con algunos criterios sencillos.<br>
          Accesible para todos: Brindamos oportunidades a personas con diferentes<br>
          perfiles financieros.</p>
      </div>
      <img src="@/assets/images/loanImg/inner3_phone.png" alt="">
      <div class="item_download" @click="downloadApp()">
        <img src="@/assets/images/loanImg/Google-play.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner_2item: [
        { title: 'Proceso de Préstamo',
          txt: 'Solicita en minutos: Rellena nuestro sencillo \n formulario en línea.',
          txt2: 'Aprobación rápida: Recibe una respuesta \n en tiempo récord.',
          txt3: 'Dinero en tu cuenta: Disfruta de tu \n préstamo directamente en tu cuenta \n bancaria.',
          img: require('@/assets/images/icon-4.png')
        },
        { title: 'Costos y Tasas',
          txt: 'Tasas competitivas: Ofrecemos tasas de \n interés justas y transparentes.',
          txt2: 'Sin sorpresas: Conoce todos los costos \n antes de aceptar tu préstamo.',
          img: require('@/assets/images/icon-5.png')
        },
        { title: 'Seguridad y Privacidad',
          txt: 'Seguridad garantizada: Tus datos están \n protegidos con las más altas medidas \n de seguridad.',
          txt2: 'Privacidad respetada: Nos comprometemos \n a proteger tu información personal.',
          img: require('@/assets/images/icon-6.png')
        },
        { title: 'Atención al Cliente',
          txt: 'Atención personalizada: Nuestro equipo \n está aquí para ayudarte en cada paso.',
          txt2: 'Preguntas frecuentes: Encuentra respuestas \n a las dudas más comunes.',
          img: require('@/assets/images/icon-7.png')
        }
      ],
      inner_4items: [
        // 您的轮播内容数据
        { id: '1', name: 'Lando Wolf', txt: 'Descarga la App', txt2: 'Download and Install the App: Visit our website \n or your device’s app store to download and install \n  We Préstamos on your mobile phone. ', img: require('@/assets/images/icon-1.png') },
        { id: '2', name: 'Alice Ura', txt: 'Registrarse y verificar', txt2: 'Sign Up: Open the app and sign up for an account \n using your mobile number. We will send you a \n verification code to ensure the security of your \n account.', img: require('@/assets/images/icon-2.png') },
        { id: '3', name: 'Marino Zavaleta', txt: 'Completar la solicitud', txt2: 'Apply for a Loan: Once you have successfully \n signed up, you can apply for a loan by selecting \n the loan amount and the repayment duration that \n suits your needs. Our loan application process is \n quick and straightforward, with minimal \n requirements.', img: require('@/assets/images/icon-3.png') }
      ]
    }
  },
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=we.prestamos.rapido')
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    font-family: Arial, Arial;
    background: #fff;
    .inner{
      width:1350px;
      // padding:50px calc((100% - 1100px) / 2) 50px;
      margin: 0 auto;
    }
    .bottom-20{
      margin-bottom: 20px;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
      background-size: 100% auto;
      background-position-x: center;
      height: 31rem;
      display: flex;
      align-items: center;
      color: black;
      .left_item{
        float: left;
        width: 740px;
        h1{
          font-size: 55px;
          // color: white;
          font-weight: 700;
          margin-bottom: 40px;
          line-height: 70px;
        }
        h2{
          padding-right: 17.5px;
          font-size: 30px;
          line-height: 26px;
          // margin-bottom: 10px;
        }
        // span{
        //   color: #FF5833;
        // }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 100px;
          .info_img1{
            width: 15px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
      }
      .right_item{
        float: right;
        // margin-right: 50px;
        img{
          width: 550px;
        }
      }
    }
    .inner2{
      padding: 80px 0;
      .content{
        padding: 0 2rem;
        div{
          padding: 30px 25px;
          background: #F5F7F4;
          text-align: center;
          font-size: 18px;
          line-height: 24px;
          border-radius: 15px;
          position: relative;
          h3{
            line-height: 50px;
          }
          p{
            text-align: left;
            margin-bottom: 10px;
            font-size: 13px;
            color: #666666;
          }
          img{
            width: 50px;
          }
        }
      }
    }
    .inner3{
      font-family: Arial, Arial;
      width: 100%;
      background: #278252;
      color: white;
      line-height: 25px;
      h1{
        line-height: 60px;
      }
      .item_download{
        display: flex;
        width: 200px;
        // background: black;
        color: white;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        img{
          width: 200px;
          height: 60px;
        }
      }
      img{
        width: 200px;
        margin: 0 150px;
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
      width: 100vw;
    }
    .inner1{
      height: 160px;
      position: relative;
      padding: 0 20px;
      h1{
        font-size: 18px !important;
        line-height: 25px !important;
        margin-bottom: 0 !important;
      }
      h2{
        font-size: 12px !important;
      }
      .left_item{
        margin: 25px 0;
        .item_download{
          margin-top: 20px;
          justify-content:start;
          img{
            width: 150px;
            height: 50px;
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .inner2{
      padding: 0;
      h1{
        font-size: 25px;
        width: 100%;
        text-align: center;
        margin-bottom: 0;
      }
      .content{
        padding: 0;
        justify-content: center !important;
        div{
          width: 90% !important;
          padding: 40px 20px 20px;
          font-size: 14px;
          margin-bottom: 20px;
          img{
            width: 60px;
            top: -30px;
            left: calc(50% - 30px);
          }
        }
      }
    }
    .inner3{
      h2{
        text-align: center;
      }
      p{
        padding: 0 30px;
      }
      img{
        margin: 20px 0 ;
      }
    }
  }
}
</style>
